/*
 * Global
 * Layout October 2017
 * ---------------------------------------------------------- */

// fonts
$sans-font-stack: system, -apple-system, BlinkMacSystemFont, "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
$mono-font-stack: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Monaco, Courier, monospace;

// colors
$link-color:       #390;
$link-hover-color: #57BD5C;

// type
$strong-color:    #2c3e50;

body {
    font-family: $sans-font-stack;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgb(96, 108, 113);
    background-color: #fff;
    margin: 0;
}


@media screen and (max-width: 900px) {
    body.docs {
        padding-top: 0;
    }
}

/*
 * Elements
 * ---------------------------------------------------------- */
a,
a:visited {
    text-decoration: none;
    color: $link-color;
}

a:hover,
a:active {
    color: $link-hover-color;
}

a[href*="//"]:not([href*="realscale.cloud66.com"]),
.link-external {
    background: url("data:image/gif;base64,R0lGODlhCAAHAIABAJO42f///yH5BAEAAAEALAAAAAAIAAcAAAINjAMJd5q2HoSLzUadKQA7") no-repeat right 5px rgba(0, 0, 0, 0);
    padding-right: 12px !important;
}


img {
    border: none;
}

h1,
h2,
h3,
h4,
strong {
    font-weight: 600;
    color: $strong-color;
}
code,
pre,
kbd {
    font-family: $mono-font-stack;
    font-size: 14px;
    background-color: #f5f7f7;
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
}
code, kbd {
    color: #e96900;
    padding: 4px 6px;
    border-radius: 2px;
}

p {
    word-spacing: 0.05em;
}



/*
 * Buttons
 * ---------------------------------------------------------- */

.Button {
    padding: 0.7em 1.7em;
    border-radius: 2em;
    display: inline-block;
    color: #fff;
    background-color: #62c462;
    transition: all 0.15s ease;
    box-sizing: border-box;
    border: 1px solid #62c462;
    font-size: 15px;
}
.Button.Button--outlined {
    background: transparent;
    border: 1px solid #449FD8;
    color: #449FD8;

    &:hover {
        color: #fff;
    }
}
.Button--small {
    font-size: 12px;
    padding: 0.3em 1em;
}


/*
 * Nav
 * ---------------------------------------------------------- */
.Header-nav .nav-link {
    font-size: 16px;
    font-weight: 600;
}
.Header-nav .nav-dropdown-container .nav-link:hover:not(.current) {
    border-bottom: none;
}
.Header-nav .nav-dropdown-container:hover .nav-dropdown {
    display: block;
}
.Header-nav .nav-dropdown-container .arrow {
    pointer-events: none;
}
.Header-nav .nav-dropdown {
    box-sizing: border-box;
    display: none;
    max-height: calc(100vh - 61px);
    min-width: 210px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    right: -10px;
    background-color: #fff;
    padding: 10px 0;
    border: 1px solid #ddd;
    border-bottom-color: #ccc;
    text-align: left;
    border-radius: 4px;
    white-space: nowrap;
}
.Header-nav .nav-dropdown li {
    line-height: 1.8em;
    margin: 0;
    display: block;
}
.Header-nav .nav-dropdown li > ul {
    padding-left: 0;
}
.Header-nav .nav-dropdown li:first-child h4 {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
}
.Header-nav .nav-dropdown a,
.Header-nav .nav-dropdown h4 {
    color: #000;
    font-size: 14px;
    padding: 0 24px 0 20px;
}
.Header-nav .nav-dropdown h4 {
    margin: 0.45em 0 0;
    padding-top: 0.45em;
    border-top: 1px solid #eee;
}
.Header-nav .nav-dropdown a {
    color: #3a5169;
    font-size: 0.9em;
    display: block;
}
.Header-nav .nav-dropdown a:hover {
    color: #427dc4;
}
.Header-nav .arrow {
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
    margin-left: 6px;
    margin-right: -14px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #ccc;
}

hr.nav-hr {
    clear: both;
    float: none;
    margin: 4px 0;
    border: 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #ffffff;
}

/* icon menus */
/* space for the icons */
.nav-link--icon  {
    padding-left: 44px !important;
}

/*
 * Header
 * ---------------------------------------------------------- */
.Header {
    background-color: rgba(255,255,255,0.96);
    box-shadow: inset 0px 0px 0px 1px #EFEFEF, 1px 1px 6px;
    height: 40px;
    padding: 16px 40px;
    position: relative;
    z-index: 2;
}
body.docs .Header {
    position: fixed;
    width: 100%;
    top: 0;
}
body.docs #nav {
    position: fixed;
}


/*
 * Nav
 * ---------------------------------------------------------- */
.Header-nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 100px;
    top: 15px;
    height: 40px;
    line-height: 40px;
}
.Header-nav .break {
    display: none;
}
.Header-nav li {
    display: inline-block;
    position: relative;
    margin: 0 0.8em;
}
.Header-nav .nav-dropdown .nav-link:hover,
.Header-nav .nav-dropdown .nav-link.current {
    border-bottom: none;
}
.Header-nav .nav-dropdown .nav-link.current::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid #42b983;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    left: 8px;
}
.nav-link {
    color: #427dc4;
    padding-bottom: 16px;
}
.is-active {
    color: #333 !important;
}
.nav-link-title {
    font-weight: 500;
    color: #2c3e50;
}
.Header-nav li:first-child .nav-link {
    padding-left: 0;
}
.nav-link:hover {
}
.nav-link.current {
    font-weight: bold;
    color: #2c3e50;
}
.nav-community .nav-link {
    margin-left: 10px;
}

#NavSub {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 30px;
    top: 10px;
    height: 40px;
    line-height: 40px;
}

/*
 * Logo
 */
#logo {
    display: inline-block;
}
#logo img {
    vertical-align: middle;
    width: 36px;
    height: 36px;
}

/*
 * Mobile
 * ---------------------------------------------------------- */
.MobileBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #fff;
    z-index: 9;
    display: none;
    box-shadow: 0 0 2px rgba(0,0,0,0.25);

    .MobileBar-button {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 8px;
        left: 12px;
        background: url("/assets/img/ui/menu.png") center center no-repeat;
        background-size: 24px;
    }

    .MobileBar-title {
        position: absolute;
        top: 13px;
        left: 44px;
        font-size: 13px;
        margin: 0;
        line-height: 1;
    }

    .MobileBar-logo {
        position: absolute;
        width: 30px;
        height: 30px;
        background: url("/assets/img/ui/logo.svg") center center no-repeat;
        top: 5px;
        left: 50%;
        margin-left: -15px;
        background-size: 30px;
    }
}





/*
 * Sidebar
 */

.sidebar {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    font-size: 0.9em;
    background: #F7F8F9;


    h2 {
        font-weight: bolder;
        margin-top: 0;
        font-size: 18px;
    }

    h3 {
        line-height: 1;
        font-weight: bold;
        font-size: 16px;
        margin-top: 4px;
        margin-bottom: 4px;

        a {
            color: #13141f;
        }
    }

    ul {
        list-style-type: none;
        margin: 0 0 16px;
        line-height: 1;
        padding: 0;
    }

    li {
        line-height: 1;
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 15px;
        font-weight: 400;
        a {
            color: #13141f;

            &:hover {
                color: $link-color;
            }
        }
        a.active {
            color: $link-color;
            cursor: default;
            font-weight: 500;
        }
    }

    .sidebar-inner {
        // box-sizing: border-box;
        width: 270px;
        padding: 30px 25px 30px 25px;
    }

    .version-select {
        vertical-align: middle;
        margin-left: 5px;
    }

    .menu-root {
        padding-left: 0;
    }

    .menu-sub {
        font-size: 0.85em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
    .sidebar-link {
        color: #7f8c8d;
        font-size: 13px;
        line-height: 1.3;
    }

    .sidebar-link.current {
        color: #000;
    }
    .sidebar-link:hover {
        padding-bottom: 1px;
        color: #333;
    }
    .section-link {
        color: rgb(52, 73, 94);
    }

    .section-link.active {
        font-weight: bold;
        color: #427dc4;
    }
    .main-menu {
        margin-bottom: 20px;
        display: none;
        padding-left: 0;
    }

    .nav-dropdown h4 {
        font-weight: normal;
        margin: 0;
    }
}

/*
 * Sidebar (Side drawer on mobile)
 * ------------------------------------------------------- */
@media screen and (max-width: 900px) {

    .sidebar {
        position: fixed;
        z-index: 10;
        background-color: #f9f9f9;
        height: 100%;
        top: 0;
        left: 0;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        transition: all 0.4s cubic-bezier(0.4, 0, 0, 1);
        -webkit-transform: translate(-300px, 0);
        transform: translate(-300px, 0);
    }
    .sidebar .sidebar-inner {
        padding: 50px 10px 10px 20px;
        box-sizing: border-box;
    }
    .sidebar .sidebar-inner-index {
        padding: 10px 10px 10px 20px;
    }

    .sidebar .main-menu {
        display: block;
    }
    .sidebar.open {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

}

.SidebarTitle {
    margin-bottom: 42px;

    &:hover {
        .SidebarTitle-subHead,
        .SidebarTitle-head {
            color: $link-color;
        }
    }

    .SidebarTitle-head {
        font-weight: 800;
        font-size: 28px;

    }
    .SidebarTitle-subHead {
        padding-top: 4px;
        font-weight: 500;
        font-size: 16px;
    }

    a {
        .SidebarTitle-head {
            color: #2c3e50;
        }

        .SidebarTitle-subHead {
            color: #6b6c7e;
        }
    }
}

.home .SidebarTitle-subHead {
    color: $link-color !important;
}

/*
 * Content Menu
 * ------------------------------------------------------- */
.content.menu {
    font-size: 1.2em;
}
.content.menu .menu-root {
    padding-left: 0;
}
.content.menu #search-form,
.content.menu .algolia-autocomplete,
.content.menu input {
    width: 100%;
}
.content.menu .aa-dropdown-menu {
    box-sizing: border-box;
}
.content.menu h3 {
    margin: 1.5em 0 0.75em;
}
.content.menu h3:before,
.content.menu h3:after {
    display: none;
}
.content.menu li {
    list-style-type: none;
    margin-top: 0.1em;
}

.Header {
    box-shadow: inset 0px 0px 0px 1px #EFEFEF, 1px 1px 6px rgba(0,0,0,0.05);
    transition: background-color 0.3s ease-in-out;
}


/*
 * Content
 * The main article
 * ------------------------------------------------------------------  */

.content {
    position: relative;
    padding: 4.2em 0;
    max-width: 680px;

    @media screen and (min-width: 1600px) {
        max-width: 820px;
    }
    @media screen and (min-width: 1800px) {
        max-width: 900px;
    }

    margin: 0 auto;

    /* Document content styles */

    p {
        font-size: 17px;
        @media screen and (min-width: 1600px) {
            font-size: 18px;
        }
        @media screen and (min-width: 1800px) {
            font-size: 20px;
        }
    }

    p.lead {
        font-size: 22px;

        @media screen and (max-width: 900px) {
            font-size: 20px;
            color: #606c71;
        }
        @media screen and (min-width: 1600px) {
            font-size: 24px;
        }
        @media screen and (min-width: 1800px) {
            font-size: 26px;
        }

        margin-bottom: 28px;
        font-weight: 400;
        color: #999;
    }

    p.lead::after {
        background-color: $link-color;
        content: "";
        display: block;
        height: .25rem;
        position: relative;
        left: 0;
        bottom: -22px;
        width: 2rem;
    }

    p.lead--noBar::after {
        background-color: transparent;
    }

    img  {
        max-width: 100%;
    }

    .light { color: #7f8c8d; }

    /* Document Headings */
    h1 {
        margin: 0 0 1em;
        font-size: 28px;

        @media screen and (max-width: 900px) {
            font-size: 22px;
        }
        @media screen and (min-width: 1400px) {
            font-size: 32px;
        }
        @media screen and (min-width: 1600px) {
            font-size: 34px;
        }
        @media screen and (min-width: 1800px) {
            font-size: 36px;
        }

        font-weight: 900;
    }


    h2 {
        margin: 20px 0 1.2em;
        padding-bottom: 0.7em;
        border-bottom: 1px solid #e8e8e8;
        font-weight: 800;
        font-size: 18px;

        @media screen and (min-width: 1600px) {
            font-size: 22px;
        }
        @media screen and (min-width: 1800px) {
            font-size: 22px;
        }
    }

    h3 {
        font-size: 17px;
        margin: 10px 0 10px;
        font-weight: 800;

        @media screen and (min-width: 1600px) {
            font-size: 18px;
        }
        @media screen and (min-width: 1800px) {
            font-size: 20px;
        }
    }

    figure {
        margin: 1.2em 0;
    }

    /* paragraphs and lists */
    p, ul, ol {
        line-height: 1.7em;
        margin: 0 0 6px 0;
        padding-bottom: 1em;
        position: relative;
        z-index: 1;

        a {
            color: inherit;
            border-bottom: 1px solid #c1c1c1;
            padding-bottom: 1px;

            &:hover {
                color: $link-color;
            }
        }
    }

    ul, ol {
        padding-left: 3em;
        position: inherit;
    }

    li {
        margin-bottom: 12px;
        padding-right: 8px;

    }

    blockquote {
        margin: 2em 0;
        padding-left: 20px;
        border-left: 4px solid #42b983;
    }

    blockquote p {
        font-weight: 600;
        margin-left: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

}

.num-round-heading {
    padding-bottom: 14px;
}
.num-round {
    position: relative;
    top: -2px;
    display: inline-block;
    background-color: $link-color;
    text-align: center;
    color: white;
    width: 26px;
    height: 26px;
    line-height: 26px;
    border-radius: 50%;
    margin-right: 8px;
    font-size: 15px;
    font-weight: bold;
}

.NextPageNav {
    font-weight: 500;
    font-size: 16px;
    padding-top: 22px;
    padding-bottom: 62px;

    @media screen and (min-width: 1400px) {
        font-size: 17px;
    }
    @media screen and (min-width: 1600px) {
        font-size: 18px;
    }
}





/*
 * Tables
 * Note: the jekyll syntax highlighter uses nested tables
 * so a .table namespace is used.
 * */
.content {
    .table {
        border: 1px solid #ddd;
        border-spacing: 0;
        border-collapse: collapse;
        margin-top: 2em;
        margin-bottom: 2em;
        overflow-x: auto;
        table-layout: fixed;
        width: 100%;
        border-radius: 3px;
    }
    .table td,
    .table th {
        line-height: 1.5em;
        padding: 0.4em 0.8em;
        border: none;
        border-bottom: 1px solid #ddd;
        font-size: 14px;
    }
    .table th {
        font-weight: bold;
        text-align: left;
    }
    .table th,
    .table tr:nth-child(2n) {
        background-color: #f8f8f8;
    }
    .table th code,
    .table tr:nth-child(2n) code {
        background-color: #efefef;
    }
}

// colors
$notice-blue:  #2c3e50;
$notice-amber: #fe7f25;
$notice-green: #2aa754;

.notice {
    margin-top: 1em;
    margin-bottom: 1em;
}

/*
 * notice
/* Default - blue */
 .notice p {
    border-color: #547e9a !important;
    background-color: #f4f7f9 !important;
    color: #2c3e50 !important;
 }

 /* Amber / Orange Warning */
 .notice-warning p,
 .notice-amber p {
     border-color: #fe7f25 !important;
     background-color: #fff6f0 !important;
     color: #fe7f25 !important;
 }
 /* Danger / Red Warning */
 .notice-danger p,
 .notice-red p {
     border-color: #C82829 !important;
     background-color: #ffeaea !important;
     color: #C82829 !important;
 }
  /* Success / Green Warning */
 .notice-green p,
 .notice-success p {
     border-color: #2aa754 !important;
     background-color: #ebfaf0 !important;
     color: #2aa754 !important;
 }

.notice p {
    padding: 12px 24px 12px 30px;
    margin-bottom: 2em;
    border-left-width: 4px;
    border-left-style: solid;
    background-color: #f8f8f8;
    position: relative;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}

.content p.tip code,
.content p.success code {
    background-color: #efefef;
}


.content hr {
    display: none;
}

.content hr.rule {
    clear: both;
    display:block;
    height: 0 !important;
    border: solid #E5E5E5;
    border-width: 1px 0 0;
    margin:1em 0;
}




@media screen and (max-width: 900px) {
    .lead {
        font-size: 16px;
    }
}

.content .PageLinks {
    border-top: 1px solid #eee;
    margin-top: 3em;
    padding-top: 1.6em;
    height: 1em;
    font-weight: 500;
}
.content .PageLinks  strong {
    color: inherit;
    font-weight: 900;
}

.content .extra {
    // max-width: 620px;
}

.ContentImage {
    margin: 1em 0;
}

/*
 * Footer
 * ------------------------------------------------------------------  */

.FooterArticle {
    color: #7f8c8d;
    margin-top: 2em;
    padding-top: 2em;
    margin-bottom: 2em;
    font-size: 0.9em;
}


/*
 * Sidebar - fixed
 * ------------------------------------------------------------------  */

.Main--fixSidebar .sidebar {
    position: fixed;
}

/*
 * Responsive
 * ------------------------------------------------------------------  */

 .content--withSidebar {
    padding-left: 120px;
 }

@media screen and (min-width: 1590px) {
    .Header {
        background-color: rgba(255, 255, 255, 0.95);
    }
}
@media screen and (max-width: 1500px) {
    .content--withSidebar {
        margin-left: 260px;
    }
}
@media screen and (max-width: 900px) {
    body {
        -webkit-text-size-adjust: none;
        font-size: 14px;
    }
    .Header {
        display: none;
    }
    #logo {
        display: none;
    }
    .nav-link {
        padding-bottom: 1px;
    }
    .nav-link:hover,
    .nav-link.current {
        border-bottom: 2px solid #42b983;
    }
    .MobileBar {
        display: block;
    }
    .Main {
        padding: 2em 1.4em 0;
    }
    /*.highlight pre {
    padding: 1.2em 1em;
    }*/
    .content {
        padding-left: 0;
    }
    .content--withSidebar {
        margin: auto;
    }
    .content h2:before,
    .content h3:before {
        content: "";
        display: block;
        margin-top: -70px;
        height: 70px;
        visibility: hidden;
    }
    .FooterArticle {
        margin-left: 0;
        text-align: center;
    }
}

/*
 * Panel
 * ------------------------------------------------------------------  */
.Panel,
.ButtonOutlined {
    box-sizing: border-box;
    background-color: #FDFDFD;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 1em;
    font-size: 14px;
}
.Panel,
.ButtonOutlined {
    h6 {
        margin: 0 0 0 0;
        text-transform: capitalize;
        color: #2c3e50;
    }
    p {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
}

.ButtonOutlined {
    display: inline-block;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-top: 1em;
    margin-bottom: 1.8em;
}
.ButtonOutlined-text {
    font-size: 14px;
    font-weight: 600;
    padding-left: 9px;
}

/*
 * Media Object
 * ------------------------------------------------------------------  */

.Media {
    display: flex;
    align-items: flex-start;
}

.Media-figure {
    margin-right: 1em;
}
.Media-heading {
    font-size: 14px;
    padding-bottom: 4px;
}
.Media-body {
    flex: 1;
}

/*
 * Home Landing Page
 * Search & CTA for each product
 * ------------------------------------------------------------------  */

body.Body--home #search-query-nav {
    display: none;
}

.LandingContainer {
    box-sizing: border-box;
    padding: 1.6em 0;
    max-width: 800px;
    margin: 0 auto;
}

.LandingContainer.content {
    max-width: 800px !important;
}

.LandingSearch {
    position: relative;
    background: #fff;
}

/*
    Landing page content
    headings, link to open source
 */
.LandingContent {
    padding-top: 8px;

    .LandingContent-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 26px;
    }
    .LandingContent-text {
        margin-top: -18px;
        font-weight: 500;
        font-size: 15px;
    }
    .LandingContent-list {
        padding-left: 0;

        .LandingContent-item {
            list-style: none;
            margin-left: 0;
            padding-left: 28px;
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }
    .LandingContent-link {
        font-weight: 500;
    }
    .LandingContent-xMuted {
        color: rgb(163, 175, 191) !important;
    }
    .LandingContent-muted {
        color: rgb(130, 140, 153) !important;
        font-weight: normal !important;
        font-size: 15px;
    }
}

.LandingContent--spaced {
    padding-bottom: 18px;
}


/*
 * Home Landing Page
 * Product CTA's
 * ------------------------------------------------------------------  */
.Landing {
    background: #FDFDFD;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
}

.Product {
    background: #fff;
    box-sizing: border-box;
    border: 1px solid #e3e3e3;
    box-shadow: 0 2px 3px 0 rgba(0,0,0, .06);
    border-radius: 6px;
    transition: all .2s ease-in-out;
}
.Product .Button {
    font-weight: 500;
}
.Product:hover {
    box-shadow: 0 2px 6px 0 rgba(0,0,0, .12);
}
.Product:hover .Button {
    background: #449FD8;
    color: #fff;
}
.Product-body {
    padding: 2.1em 1.6em;
}
.Product .Product-title {
    font-size: 19px;
    font-weight: 800;
    margin: 0;
    padding: 0 0 1em;
    line-height: 1;
}
.Product .Product-text {
    font-size: 16px;
    margin: 0;
    padding: 0 0 1em;
    min-height: 64px;
}
.Product-topBar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #eee;
    height: 5px;
}
.Product-image {
    margin-top: 1px;
}



/*
 * Related Links
 * .SearchResults
 * ------------------------------------------------------------------  */
.content .RelatedLinks-list  {
    list-style: none;
    padding-left: 0;
}
.content .RelatedLinks-title {
    line-height: 1;
}

/*
 * .Footer
 * .SearchResults
 * ------------------------------------------------------------------  */
.Footer {
    margin-top: 96px;
    padding: 2.8em 0;
    color: #999;
    border-top: 1px solid #e8e8e8;
    font-size: 14px;

    a {
        color: #999;
        &:hover {
            color: $link-color;
        }
    }
}

/*
 * .Shaded
 * shaded areas in main content, .Section's can contain .Panels
 * ------------------------------------------------------------------  */
.Section {
    position: relative;
    margin-bottom: 2em;
    margin-top: 2em;
}
.Section-body {
    padding-right: 1.6em;
    padding-left: 1.6em;
    padding-top: 2.4em;
    padding-bottom: 2.4em;
}
.Section--shaded {
    background-color: #FDFDFD;
    border: 1px solid #ededed;
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.03);

    .Product-topBar {
        height: 4px;
    }

    .Panel {
        background-color: #fff;
        box-shadow: 0 2px 3px 0 rgba(0,0,0, .08);
        padding-top: 1.4em;
        padding-bottom: 1.4em;
        transition: all 150ms ease 0s;
        .Media-heading {
            font-weight: bold;
            font-size: 17px;
        }
    }
    .Panel:hover {
        box-shadow: 0 2px 6px 0 rgba(0,0,0, .16);
        transform: translateY(-2px);
    }
}
.Section--shaded .Grid:last-child  {
    margin-bottom: 0;
    padding-bottom: 0;
}


/*
 * Tabs
 * Container
 * ------------------------------------------------------------------  */
.Tabs {
    margin: 3em 0;
}

.Tabs--enclosed {
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.06);

    .TabMini {
        padding: 0 0 0 16px !important;
    }

    .Tabs-content {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 22px;

        p {
            font-size: 14px;
        }
    }
}


/*
 * .NavCats
 * Navigation Categories: How-to, Guide, Tutorial
 * ------------------------------------------------------------------  */
.TabMini {
    list-style: none;
    padding: 0 !important;
    margin: 8px 0 21px 0 !important;
    border-bottom: 1px solid #ddd;
}
.TabMini-item {
    display: inline-block;
    list-style: none;
}
.TabMini-link {
    position: relative;
    top: 1px;
    display: block;
    padding: 14px;
    font-size: 14px;
    font-weight: bold !important;
    margin: 0 1.6em 0 0;
    outline: none;
    transition: none;

    &:hover {
        color: #24292e;
        text-decoration: none;
        border-bottom: 3px solid #ededed;
    }
}

li.active .TabMini-link {
    border-bottom: 3px solid #427dc4;
    font-weight: 500;
    color: #24292e;
    cursor: auto;
}

/*
 * Toc
 * Primary Table of Contents
 * ------------------------------------------------------------------  */
.content .Toc {
    h2 {
        padding-top: 0.75em;
        border-bottom: none !important;
    }
    h2:first-child {
        padding-top: 0;
    }
    ul {
        overflow: hidden;
        list-style: none;
        margin: 0;
        padding: 0;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
    }
    li {
        line-height: 1;
        font-weight: 500;
        padding: 12px 12px 12px 44px;
        background: transparent url('/images/ui/document.svg') no-repeat 15px 14px;
        background-size: 16px;
        border-bottom: 1px solid #eee;
        font-size: 16px;
        min-height: 28px;
    }
    li:hover {
        background-color: #FDFDFD;
        p {
            color: #000;
        }
    }
    li:last-child {
        border-bottom: none;
    }
    p {
        line-height: 1.2;
        margin: 0;
        padding: 0;
        padding-top: 3px;
        color: #999;
        font-weight: normal;
        font-size: 13px;
    }
    .Tag {
        float: right;
        margin-right: 4px;
        background: transparent;

        &:last-child {
            padding-right: 0;
        }
    }
}

/*
 * Tag
 * Base styles for tags labels.
 * ------------------------------------------------------------------  */
.Tag {
    line-height: 1;
    display: inline-block;
    color: #aaa;
    font-size: 9px;
    background: #fafafa;
    padding: 3px;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 0.15px;
}
.Tag:empty {
    display: none;
}

.Tag--lrg {
    padding: 4px;
    font-size: 11px;
    background: #F1F8FF;
    border: 1px solid #d5e2f2;
    color: #6a737d;
}
.Tag--lrg:hover {
    color: #35689e;
    border: 1px solid #bcd6f4;
}


/*
 *
 * Callout
 * ------------------------------------------------------------------  */


.Callout {
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .06);
    border: 1px solid #E5D6B0;
    border-radius: 4px;
    background: #fff9ea;
    color: #000;
    padding: 12px;
    font-weight: 500;
    vertical-align: middle;
    margin-top: 30px;
    margin-bottom: 36px;

  .Callout-text {
      display: inline-block;
      font-size: 17px;
      font-weight: 500;
      margin: 0 !important;
      padding: 0;
      padding-right: 18px;
      color: #000;
  }

  .Callout-strong {
      color: #000;
      font-weight: 600;
  }

  .Callout-button {
      border-radius: 4px;
      display: inline-block;
      padding: 12px 14px;
      font-weight: 800;
      background-color: #64c964;
      color: white !important;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: all .35s ease-in-out;

      &:hover {
          background-color: #57b257;
      }
  }
}

.Related {
    position: relative;
    margin-left: -13px;
    margin-right: -13px;
    margin-bottom: 16px;
    font-size: 14px;
    background: #fafafa;
    padding: 16px;
    border-radius: 6px;

    .Related-title {
        margin: 0;
        padding-bottom: 6px;
    }

    .Related-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 0;
            line-height: 1.2;
            padding-bottom: 3px;
            padding-top: 3px;
            font-size: 14px;
            @media screen and (max-width: 900px) {
                font-size: 13px;
            }
        }
        a {
            border: none;
        }
    }
}

.Image {
    display: block;
    margin: 0 auto;
    width: 80%;
    margin-top: 60px;
    margin-bottom: 60px;
}

/* Message Boxes */
/* colors */
$greyBorderColor: #ddd;
$greyBackground:  #FAFAFA;

$greenBorderColor: #BDDBA2;
$greenBackground:  #F4FFEF;
$greenContent:     #339900;

$yellowBorderColor: #eadbb4;
$yellowBackground:  #fcf7ea;


/* base */
.Message {
  border-radius: 6px;
  padding: 14px;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 30px;

  .Message-text {
    padding-left: 8px;
    font-size: 15px;
    line-height: 1.35;
  }
}
/* Message Modifiers - colors */

.Message--grey {
    background-color: $greyBackground;
    border: 1px solid $greyBorderColor;
    color: #2c3e50;
}
.Message--yellow {
    border: 1px solid  $yellowBorderColor;
    background: $yellowBackground;
    color: #2c3e50;
}
.Message--green {
    border: 1px solid $greenBorderColor;
    background:  $greenBackground;
    color: $greenContent;
}

.Featured {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 24px;
  background-color: #F8FAFC;
  padding: 24px;
  border-radius: 20px;

  h3 {
    color: #253544;
  }

  h3:first-child {
    margin-top: 0;
  }

  p {
    color: #363e42;
  }

  p:last-child {
    padding-bottom: 0;
  }
}


/* Global Utilities */

.u-displayFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.u-displayBlock {
    display: block !important;
}

.u-fontWeight500 {
    font-weight: 500;
}
.u-fontWeight600 {
    font-weight: 600;
}
.u-fontWeight800 {
    font-weight: 800;
}
.u-fontWeight900 {
    font-weight: 900;
}

.u-fontSize15 {
    font-size: 15px;
}
.u-fontSize14 {
    font-size: 14px;
}
